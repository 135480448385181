/* eslint-disable no-nested-ternary */

import { Customer } from "@/models/customers";
import React, { useEffect, useState } from "react";
import { AnimatedCustomers, CustomerCard, Filter, Pagination } from "./components";
import { useCustomer, useUpdateCustomer } from "./hooks/useCustomers";

export const CustomersList = () => {
  const [skip, setSkip] = useState(0);
  const [query] = useState("");
  const { data, refetch, isLoading } = useCustomer(query, skip);
  const { mutate: update } = useUpdateCustomer();
  const [customers, setCustomers] = useState<Customer[]>();

  useEffect(() => {
    setCustomers(data?.customers!);
  }, [data]);

  return (
    <div className=" flex flex-col justify-center items-center">
      <Filter
        onQChange={() => {
          //  setQuery(query);
        }}
      />
      {isLoading ? (
        <AnimatedCustomers />
      ) : customers?.length === 0 ? (
        <div className=" mt-[200px] max-w-fit flex justify-center items-center bg-[#757687] p-4 rounded-3xl text-white m-auto h-8 font-medium ">
          No customers!
        </div>
      ) : (
        <>
          <div className=" w-full flex flex-row flex-wrap  sm:p-10">
            {customers?.map((customer) => (
              <div key={customer._id} className="pl-5 pt-5 w-[310px]">
                <CustomerCard
                  onUpdate={(data) => {
                    update(
                      { customerId: customer._id, updates: data },
                      {
                        onSuccess() {
                          refetch();
                        }
                      }
                    );
                  }}
                  customer={customer}
                />
              </div>
            ))}
          </div>
          <Pagination
            length={data?.customers.length!}
            count={data?.count!}
            skiper={(skip) => {
              setSkip(skip);
            }}
          />
        </>
      )}
    </div>
  );
};
