import { Button } from "@/components/Buttons";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between">
      <div className="flex items-start  gap-2">
        <Button
          variant="btn-active"
          onClick={() => navigate("/marketing/campaign/create-campaign")}
        >
          Create New Campaign
        </Button>
        <Button disabled variant="btn-accent">
          <div className="flex gap-1 items-center">
            <span>Create With AI </span>
            <img className=" w-5 h-5" src="/img/icons/ai.svg" alt="" />{" "}
          </div>
        </Button>
      </div>
      <div>
        <Button variant="btn-active">Export</Button>
      </div>
    </div>
  );
};
