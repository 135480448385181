import { TextField } from "@/components/Forms";
import { FlowFormData } from "@/models/flow";
import { useFormContext } from "react-hook-form";
import { NodeFormWrapper } from "./NodeFormWrapper";

export const FlowFormMessenger = () => {
  const {
    register,
    formState: { errors }
  } = useFormContext<FlowFormData>();

  return (
    <NodeFormWrapper description="This node send forms to the user">
      <TextField
        name="leadgenFormId"
        register={register}
        label="Messenger Lead generation Form Id"
        error={errors.leadgenFormId?.message}
      />
    </NodeFormWrapper>
  );
};
