/* eslint-disable no-nested-ternary */
import { Button } from "@/components/Buttons";
import data from "./data.json";

export type Template = {
  id: string;
  name: string;
  category: string;
  language: string;
  status: string;
  messagesDelivered: number;
  messagesReadRate: number;
  lastEdit: string;
  topBlockReason?: string | null;
  text: string;
  parameters: string[];
};
export const TemplatesList = () => {
  // const { templates, setTemplates } = useState();
  const templates: Template[] = data;
  return (
    <div className="flex justify-center w-full">
      <div className="overflow-x-auto w-full ">
        <table className="table w-full">
          {/* head */}
          <thead>
            <tr>
              <th>
                <div>
                  <input type="checkbox" className="checkbox" />
                </div>
              </th>
              <th>Template Name</th>
              <th>Category</th>
              <th>Language</th>
              <th>Status</th>
              <th>Messages delivered</th>
              <th>Last edited</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {templates?.map((template) => (
              <tr key={template.id}>
                <th>
                  <div>
                    <input type="checkbox" className="checkbox" />
                  </div>
                </th>
                <td>
                  <div className="flex items-center gap-3">
                    <div>
                      <div className="font-bold">{template.name}</div>
                    </div>
                  </div>
                </td>
                <td>
                  {template.category}
                  {/* <span className="badge badge-ghost badge-sm">Desktop Support Technician</span> */}
                </td>
                <td>{template.language}</td>
                <td>
                  <span
                    className={`badge badge-sm ${
                      template.status === "In Review"
                        ? "badge-warning text-yellow-700"
                        : template.status === "Rejected"
                        ? "badge-error text-red-900"
                        : "badge-accent text-green-900"
                    }`}
                  >
                    {template.status}
                  </span>
                </td>
                <td>{template.messagesDelivered}</td>
                <td>{template.lastEdit}</td>
                <th>
                  <button type="button" className="btn btn-ghost btn-xs">
                    <Button variant="btn-accent">Send Now</Button>
                  </button>
                </th>
              </tr>
            ))}
          </tbody>
          {/* foot */}
        </table>
      </div>
    </div>
  );
};
