import { Group } from "@/pages/Customers/components/groups/Group";
import React from "react";

const mockGroups = [
  { id: 1, name: "All Customers", customerCount: 120 },
  { id: 2, name: "Jeddah Branch", customerCount: 95 },
  { id: 3, name: "High Buyers", customerCount: 45 },
  { id: 4, name: "All Arab National", customerCount: 80 },
  { id: 5, name: "Indian National", customerCount: 65 },
  { id: 6, name: "Saudi National", customerCount: 30 }
];

export const CustomersGroups = () => (
  <div className="border-gray-200 flex flex-row items-center p-4 gap-2">
    {mockGroups.map((group) => (
      <Group key={group.id} groupName={group.name} customerCount={group.customerCount} />
    ))}
    <div className=" hover:bg-gray-200 rounded-md cursor-pointer">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="gray"
        className=" w-16"
      >
        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg>
    </div>
  </div>
);
