import { Navigate, Route, Routes } from "react-router-dom";
import { GuardRoute } from "@/components/GuardRoute";
import { SignInForm, SignUpForm } from "@/pages/Auth";
import { Dashboard } from "@/pages/Dashboard";
import { BotFlow } from "@/pages/BotFlow";
import SingleChatPage, { LiveChat } from "@/pages/LiveChat";
import { Orders, OrderDetails } from "@/pages/Orders";
import { Products } from "@/pages/Products";
import { Catalog, CategoryPage, ProductDetails } from "@/pages/Catalogs";
import { EmailVerification, PasswordReset, ResetRequest } from "@/pages/VerifyAndReset";
import { Customers } from "./pages/Customers";
import { Configuration } from "./pages/Configurations";
import { Templates } from "./pages/Marketing/Templates";
import { CreateTemplate } from "./pages/Marketing/CreateTemplate";
import { Campaign } from "./pages/Campaign/Campaign";
import { CreateCampaign } from "./pages/Campaign/CreateCampaign";

const App = () => (
  <Routes>
    <Route path="/auth">
      <Route path="signin" element={<SignInForm />} />
      <Route path="signup" element={<SignUpForm />} />
    </Route>
    <Route path="/account">
      <Route path="verification/:key" element={<EmailVerification />} />
      <Route path="reset-password" element={<ResetRequest />} />
      <Route path="reset-password/:key" element={<PasswordReset />} />
    </Route>
    <Route element={<GuardRoute />}>
      <Route path="/" element={<Navigate to="/dashboard" />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/bot-flow" element={<BotFlow />} />
      <Route path="/orders" element={<Orders />} />
      <Route path="/orders/:orderId" element={<OrderDetails />} />
      <Route path="/products" element={<Products />} />
      <Route path="/live-chat" element={<LiveChat />} />
      <Route path="/live-chat/:chatId" element={<SingleChatPage />} />
      <Route path="/catalog" element={<Catalog />} />
      <Route path="/catalog/:parentId" element={<CategoryPage />} />
      <Route path="/catalog/products/:prodId" element={<ProductDetails />} />
      <Route path="/marketing/templates" element={<Templates />} />
      <Route path="/marketing/audience" element={<Customers />} />
      <Route path="/marketing/campaign" element={<Campaign />} />
      <Route path="/marketing/campaign/create-campaign" element={<CreateCampaign />} />
      <Route path="/marketing/templates/create-template" element={<CreateTemplate />} />
      <Route path="/configurations" element={<Configuration />} />
      <Route path="/customers" element={<Customers />} />
    </Route>
  </Routes>
);

export default App;
