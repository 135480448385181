/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { ReactNode, useState, useEffect } from "react";

// Define the type for the props (children are the content passed inside the component)
interface IPhonePreviewProps {
  children: ReactNode;
}

export const IPhonePreview: React.FC<IPhonePreviewProps> = ({ children }) => {
  const [position, setPosition] = useState({ x: 1300, y: 130 });
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  // const [isCollapsed, setIsCollapsed] = useState(false);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.x,
      y: e.clientY - position.y
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (isDragging) {
      setPosition({
        x: e.clientX - offset.x,
        y: e.clientY - offset.y
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const step = 10;
    switch (e.key) {
      case "ArrowUp":
        setPosition((prev) => ({ ...prev, y: prev.y - step }));
        break;
      case "ArrowDown":
        setPosition((prev) => ({ ...prev, y: prev.y + step }));
        break;
      case "ArrowLeft":
        setPosition((prev) => ({ ...prev, x: prev.x - step }));
        break;
      case "ArrowRight":
        setPosition((prev) => ({ ...prev, x: prev.x + step }));
        break;
      default:
        break;
    }
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    setIsDragging(true);
    const touch = e.touches[0];
    setOffset({
      x: touch.clientX - position.x,
      y: touch.clientY - position.y
    });
  };

  // const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
  //   if (isDragging) {
  //     const touch = e.touches[0];
  //     setPosition({
  //       x: touch.clientX - offset.x,
  //       y: touch.clientY - offset.y
  //     });
  //   }
  // };

  // const handleTouchEnd = () => {
  //   setIsDragging(false);
  // };

  // const toggleCollapse = () => {
  //   setIsCollapsed(!isCollapsed);
  // };

  useEffect(() => {
    if (isDragging) {
      window.addEventListener("mousemove", handleMouseMove);
      window.addEventListener("mouseup", handleMouseUp);
    } else {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging]);

  return (
    <div>
      {/* Draggable Tab */}
      <div
        role="button"
        aria-label="Drag Handle"
        tabIndex={0}
        onKeyDown={handleKeyDown}
        onMouseDown={handleMouseDown}
        onTouchStart={handleTouchStart}
        className="w-[375px] h-[60px] flex justify-center rounded-t-md cursor-grab"
        style={{
          left: `${position.x}px`,
          top: `${position.y - 30}px`, // Positioning the tab above the phone
          position: "fixed",
          zIndex: 50
        }}
      >
        <div
          className="flex flex-row gap-0 bg-gray-200 w-[100px] justify-center rounded-lg"
          style={{ cursor: isDragging ? "grabbing" : "grab" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="black"
            className=" w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15"
            />
          </svg>
        </div>

        {/* <button
          type="button"
          onClick={toggleCollapse}
          className="ml-4 p-1 bg-white text-black rounded"
        >
          {isCollapsed ? "Expand" : "Collapse"}
        </button> */}
      </div>

      {/* iPhone preview (shown only if not collapsed) */}
      {
        // !isCollapsed &&
        <div
          role="application"
          aria-label="iPhone preview"
          className="w-[375px] h-[667px] bg-black border-[16px] border-black rounded-[40px] shadow-lg overflow-hidden fixed z-50"
          style={{
            left: `${position.x}px`,
            top: `${position.y}px`
          }}
        >
          {/* iPhone notch */}
          <div className="absolute top-[-16px] left-1/2 w-[210px] h-[30px] bg-black rounded-b-[20px] transform -translate-x-1/2" />

          {/* iPhone screen */}
          <div className="w-full h-full bg-white rounded-[10px] overflow-hidden">{children}</div>
        </div>
      }
    </div>
  );
};
