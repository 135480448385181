import { inject, injectable } from "inversify";
import {
  FacebookAuthResponse,
  FacebookUser,
  InstagramUser,
  WhatsappUser,
  User,
  WabaAuthResponse
} from "@/models/auth";
import { DeliveryArea, DeliveryData } from "@/models/DeliveryArea";
import { SERVICES_TYPES } from "@/ioc/SERVICES_TYPES";
import { HttpClient } from "@/services/client";
import { IUserApi } from "./IUserApi";

@injectable()
export class UserApi implements IUserApi {
  @inject(SERVICES_TYPES.HTTP_CLIENT)
  private client: HttpClient;

  private readonly baseUrl = "/user/me";

  async me(): Promise<User> {
    const { data } = await this.client.get<User>(this.baseUrl);
    return data;
  }

  async updateMe(credentials: any): Promise<User> {
    const { data } = await this.client.post<User>(this.baseUrl, credentials);
    return data;
  }

  async connectFacebook(response: FacebookAuthResponse): Promise<FacebookUser> {
    const { data } = await this.client.post<FacebookUser>(
      `${this.baseUrl}/facebook/connect`,
      response
    );
    return data;
  }

  async connectInstagram(response: FacebookAuthResponse): Promise<InstagramUser> {
    const { data } = await this.client.post<InstagramUser>(
      `${this.baseUrl}/instagram/connect`,
      response
    );
    return data;
  }

  async connectWhatsapp(response: WabaAuthResponse): Promise<WhatsappUser> {
    // eslint-disable-next-line
    console.log("w-ap", response);
    const { data } = await this.client.post<WhatsappUser>(
      `${this.baseUrl}/whatsapp/connect`,
      response
    );
    return data;
  }

  async disconnectFacebook(): Promise<void> {
    await this.client.post(`${this.baseUrl}/facebook/disconnect`);
  }

  async disconnectInstagram(): Promise<void> {
    await this.client.post(`${this.baseUrl}/instagram/disconnect`);
  }

  /**
   * Configurations
   */
  async getDeliveryAreas(): Promise<DeliveryArea[]> {
    const fullUrl = `${this.baseUrl}/config/delivery-area`;
    const { data } = await this.client.get(fullUrl);
    return data.deliveryAreas;
  }

  async setDeliveryEnabled(enabled: { enabled: boolean }): Promise<void> {
    const fullUrl = `${this.baseUrl}/config/delivery`;
    await this.client.post(fullUrl, enabled);
  }

  async addDeliveryArea(deliveryAreaData: DeliveryData): Promise<DeliveryArea> {
    const fullUrl = `${this.baseUrl}/config/delivery-area`;
    const { data } = await this.client.post(fullUrl, deliveryAreaData);
    return data;
  }

  async updateDeliveryArea({
    locationId,
    deliveryAreaData
  }: {
    locationId: string;
    deliveryAreaData: DeliveryData;
  }): Promise<DeliveryArea> {
    const fullUrl = `${this.baseUrl}/config/delivery-area/${locationId}`;
    const { data } = await this.client.patch(fullUrl, deliveryAreaData);
    return data;
  }

  async deleteDeliveryArea(locationId: string): Promise<void> {
    const fullUrl = `${this.baseUrl}/config/delivery-area/${locationId}`;
    await this.client.delete(fullUrl);
  }

  async verifyMe(payload: { email: string; newEmail: string }): Promise<User> {
    const fullUrl = "conf/verify/again";
    const { data } = await this.client.post<User>(fullUrl, payload);
    return data;
  }

  async updateWhatsappUser(user: Partial<WhatsappUser>): Promise<User> {
    const { data } = await this.client.post<User>("user/updateWA", user);
    return data;
  }
}
