import { useQueryClient } from "react-query";
import { useAppDispatch, useRevokeToken } from "@/hooks";
import { authActions } from "@/store/reducers/auth.reducer";
import { useLayout } from "../context/LayoutContext";
import { SidebarItem } from "./components/SidebarItem";

export const Sidebar = () => {
  const { isExpanded, sidebarRef, handleSidebarExpansion } = useLayout();
  const dispatch = useAppDispatch();

  const { mutate: signOut } = useRevokeToken();

  const queryClient = useQueryClient();

  return (
    <div
      ref={sidebarRef!}
      className={`l_sidebar animate__animated animate__fadeInLeft animate__delay-1s
      ${isExpanded ? "expanded_sidebar" : ""}`}
    >
      <div className="sidebar flex justify-between">
        <div>
          {/* eslint-disable-next-line */}
          <div
            role="button"
            className="sidebar_item"
            onClick={() => handleSidebarExpansion(!isExpanded)}
          >
            <img className="w-6" src="/img/sidebar-icons/bars-solid.svg" alt="bars" />
            <span className="font-semibold text-calm-blue">Main Menu</span>
          </div>
          <SidebarItem text="Dashboard" path="/dashboard" icon="house-solid.svg" />
          <SidebarItem text="Bot Flow" path="/bot-flow" icon="diagram-project-solid.svg" />
          <SidebarItem text="Live Chat" path="/live-chat" icon="chat-dots.svg" />
          <SidebarItem text="Orders" path="/orders" icon="bag-shopping-solid.svg" />
          <SidebarItem text="Catalog" path="/catalog" icon="catalog.svg" />
          <SidebarItem text="Customers" path="/customers" icon="customers.svg" />
          <SidebarItem text="Products" path="/products" icon="cart-shopping-solid.svg" />
          <SidebarItem
            text="Marketing"
            icon="rocket.svg"
            childs={[
              { text: "Templates", path: "/marketing/templates", icon: "template.svg" },
              { text: "Audience", path: "/marketing/audience", icon: "customers.svg" },
              { text: "Campaign", path: "/marketing/campaign", icon: "megaphone.svg" }
            ]}
          />
          <SidebarItem text="Configurations" path="/configurations" icon="configurations.svg" />
        </div>
        <div className="border-t-2 rounded-md pt-2">
          <SidebarItem
            text="Sign out"
            icon="logout-icon.svg"
            onClick={() => {
              signOut();
              dispatch(authActions.signOut());
              queryClient.clear();
            }}
          />
        </div>
      </div>
    </div>
  );
};
