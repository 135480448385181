import React from "react";

// Define props for the component
type Props = {
  groupName: string; // Name of the group
  customerCount: number; // Number of customers
};

// SVG Icon for the group (you can replace this with any SVG icon)
const GroupIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    style={{ marginRight: "8px", color: "#4A90E2" }} // Change color as needed
  >
    <path
      fill="currentColor"
      d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-4.41 0-8 3.59-8 8h2c0-3.31 2.69-6 6-6s6 2.69 6 6h2c0-4.41-3.59-8-8-8zm6-10c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2zm-12 0c1.1 0 2 .9 2 2s-.9 2-2 2-2-.9-2-2 .9-2 2-2z"
    />
  </svg>
);
// Styles for the component
const styles: { [key: string]: React.CSSProperties } = {
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f0f4f8",
    padding: "12px",
    borderRadius: "8px",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    margin: "8px 0",
    cursor: "pointer"
  },
  details: {
    display: "flex",
    flexDirection: "column"
  },
  groupName: {
    margin: 0,
    fontSize: "18px",
    fontWeight: "bold"
  },
  customerCount: {
    fontSize: "14px",
    color: "#666"
  }
};

export const Group = ({ groupName, customerCount }: Props) => (
  <div style={styles.container}>
    <GroupIcon />
    <div style={styles.details}>
      <h4 style={styles.groupName}>{groupName}</h4>
      <span style={styles.customerCount}>{customerCount} Customers</span>
    </div>
  </div>
);
