/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLayout } from "@/layout/context/LayoutContext";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react"; // Import useState

type Child = {
  text: string;
  path: string;
  icon: string;
};

type SidebarItemProps = {
  text: string;
  icon: string;
  childs?: Child[];
  path?: string;
  onClick?: () => void;
};

export const SidebarItem = ({ text, icon, path, onClick, childs }: SidebarItemProps) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { handleSidebarExpansion } = useLayout();

  const [isExpanded, setIsExpanded] = useState(false); // State to manage expansion

  const handleClick = (subpath?: string) => {
    if (onClick) onClick();
    if (path) navigate(path);
    if (subpath) navigate(subpath);
    handleSidebarExpansion(false);
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev); // Toggle expansion state
  };

  return (
    <div className="flex flex-col">
      <div
        className={`${pathname === path ? "sidebar_active_item" : ""} sidebar_item`}
        onClick={() => (childs ? toggleExpand() : handleClick())} // Call toggleExpand on click
      >
        <img className="w-6 h-6" src={`/img/sidebar-icons/${icon}`} alt={text} />
        <div className="flex flex-col">
          <span className="font-semibold text-calm-blue">{text}</span>
        </div>
      </div>

      {childs &&
        childs.length > 0 &&
        isExpanded && ( // Render childs only if expanded
          <div className="flex flex-col -mt-[20px] ml-[10px]">
            {childs.map((child) => (
              <div
                key={child.text}
                className={`p-2 flex flex-row gap-2 rounded-md mt-1 cursor-pointer hover:bg-[#9ab3ff80] ${
                  pathname === child.path ? "sidebar_active_item" : ""
                }`}
                onClick={() => handleClick(child.path)}
              >
                <img
                  className="w-5 h-5"
                  src={`/img/sidebar-icons/${child.icon}`}
                  alt={child.text}
                />
                <div className="font-semibold text-calm-blue text-sm">{child.text}</div>
              </div>
            ))}
          </div>
        )}
    </div>
  );
};
