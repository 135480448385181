import { SelectAudience } from "./components/CreateCampain/SelectAudience";
import { SelectTemplate } from "./components/CreateCampain/SelectTemplate";
import { Steps } from "./components/CreateCampain/Steps";
import { useCreateCampaign } from "./Context/CreateCampaignContext";

export const CreateCampaign = () => {
  const { step } = useCreateCampaign();
  return (
    <div className="flex flex-col gap-3 items-center">
      <Steps />
      {step === 1 && <SelectTemplate />}
      {step === 2 && <SelectAudience />}
    </div>
  );
};
