/* eslint-disable @typescript-eslint/no-unused-vars */
import { CarouselCard, TemplateButton } from "@/models/templatees";
import React, { createContext, useState } from "react";

type typeCreateCarouselContextType = {
  name: string;
  category: string;
  language: string;
  carouselCards: CarouselCard[];
  text: string;
  textParameters: string[];
  setName: (prop: string) => void;
  setCategory: (prop: string) => void;
  setLanguage: (prop: string) => void;
  setText: (prop: string) => void;
  setTextParameters: (prop: string[]) => void;
  handleAddCarousel: () => void;
  handleRemoveCarousel: (carouselOrder: number) => void;
  handleUpdateCarousel: (props: { order: number; key: string; value: string }) => void;
  handleAddCarouselButton: (carouselOrder: number) => void;
  handleRemoveCarouselButton: (carouselOrder: number, buttonOrder: number) => void;
  handleUpdateCarouselButton: (
    carouselOrder: number,
    updates: { order: number; key: string; value: string }
  ) => void;
};

const defaultCarousel: typeCreateCarouselContextType = {
  name: "",
  category: "",
  language: "",
  text: "Hello",
  textParameters: [],
  carouselCards: [],
  setName: () => {},
  setCategory: () => {},
  setLanguage: () => {},
  setText: () => {},
  setTextParameters: () => {},
  handleAddCarousel: () => {},
  handleRemoveCarousel: () => {},
  handleUpdateCarousel: () => {},
  handleAddCarouselButton: () => {},
  handleRemoveCarouselButton: () => {},
  handleUpdateCarouselButton: () => {}
};

export const CreateCarouselContext = createContext<typeCreateCarouselContextType>(defaultCarousel);

export const CreateCarouselProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [name, setName] = useState<string>("");
  const [text, setText] = useState<string>("");
  const [textParameters, setTextParameters] = useState<string[]>([]);
  const [category, setCategory] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [carouselCards, setCarouselCards] = useState<CarouselCard[]>([]);

  const handleAddCarousel = () => {
    if (carouselCards.length !== 10) {
      const order =
        carouselCards?.length > 0 ? carouselCards[carouselCards.length - 1].order + 1 : 1;
      const carousel: CarouselCard = {
        order,
        media: null,
        body: "hello..",
        bodyParameters: [],
        buttons: [
          {
            order: 1,
            buttonType: "url",
            buttonText: "Visit our website",
            buttonUrl: "",
            buttonPhoneNumber: ""
          }
        ]
      };
      const newCarouselCards = [...carouselCards, carousel];
      setCarouselCards(newCarouselCards);
    }
  };
  const handleRemoveCarousel = (carouselOrder: number) => {
    const newCarouselCards = carouselCards.filter((carousel) => carousel.order !== carouselOrder);
    setCarouselCards(newCarouselCards);
  };

  const handleUpdateCarousel = (updates: { order: number; key: string; value: string }) => {
    const updatedCarousel = carouselCards?.map((carousel) => {
      if (carousel.order === updates.order) {
        return {
          ...carousel,
          [updates.key]: updates.value
        };
      }
      return carousel; // Keep other carousel cards unchanged
    });
    setCarouselCards(updatedCarousel);
  };

  // Add a button to a carousel card
  const handleAddCarouselButton = (carouselOrder: number) => {
    const updatedCarousel = carouselCards.map((carousel) => {
      if (carousel.order === carouselOrder) {
        const buttonOrder =
          carousel.buttons.length > 0 ? carousel.buttons[carousel.buttons.length - 1].order + 1 : 1;
        const newButton: TemplateButton = {
          order: buttonOrder,
          buttonType: "url",
          buttonText: "New Button",
          buttonUrl: "",
          buttonPhoneNumber: ""
        };
        return {
          ...carousel,
          buttons: [...carousel.buttons, newButton]
        };
      }
      return carousel;
    });
    setCarouselCards(updatedCarousel);
  };

  // Remove a button from a carousel card
  const handleRemoveCarouselButton = (carouselOrder: number, buttonOrder: number) => {
    const updatedCarousel = carouselCards.map((carousel) => {
      if (carousel.order === carouselOrder) {
        const newButtons = carousel.buttons.filter((button) => button.order !== buttonOrder);
        return {
          ...carousel,
          buttons: newButtons
        };
      }
      return carousel;
    });
    setCarouselCards(updatedCarousel);
  };

  // Update a button within a carousel card
  const handleUpdateCarouselButton = (
    carouselOrder: number,
    buttonUpdates: { order: number; key: string; value: string }
  ) => {
    const updatedCarousel = carouselCards.map((carousel) => {
      if (carousel.order === carouselOrder) {
        const updatedButtons = carousel.buttons.map((button) => {
          if (button.order === buttonUpdates.order) {
            return {
              ...button,
              [buttonUpdates.key]: buttonUpdates.value
            };
          }
          return button;
        });
        return {
          ...carousel,
          buttons: updatedButtons
        };
      }
      return carousel;
    });
    setCarouselCards(updatedCarousel);
  };

  const value = React.useMemo(
    () => ({
      name,
      category,
      language,
      text,
      textParameters,
      carouselCards,
      setName,
      setCategory,
      setLanguage,
      setText,
      setTextParameters,
      handleAddCarousel,
      handleRemoveCarousel,
      handleUpdateCarousel,
      handleAddCarouselButton, // Add new functions to context
      handleRemoveCarouselButton,
      handleUpdateCarouselButton
    }),
    [
      name,
      category,
      language,
      text,
      textParameters,
      carouselCards,
      setName,
      setCategory,
      setLanguage,
      setText,
      setTextParameters,
      handleAddCarousel,
      handleRemoveCarousel,
      handleUpdateCarousel,
      handleAddCarouselButton, // Add new functions to context
      handleRemoveCarouselButton,
      handleUpdateCarouselButton
    ]
  );

  return <CreateCarouselContext.Provider value={value}>{children}</CreateCarouselContext.Provider>;
};

export const useCreateCarousel = () => {
  const context = React.useContext(CreateCarouselContext);
  if (!context) {
    throw new Error("useCreateCarousel must be used within a CreateTemplateProvider");
  }
  return context;
};
