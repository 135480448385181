import { IPhonePreview } from "@/pages/Marketing/IphonePreview";
import React, { useEffect } from "react";

const carouselCards = [
  {
    id: 1,
    body: "Our selection for you *{{product}}* for just *{{price}}* 🤩 \n Shop Now and do not miss the opportunity😎.",
    bodyParameters: [{ key: "product" }, { key: "price" }],
    buttons: [
      {
        id: 1,
        type: "url"
      },
      {
        id: 2,
        type: "url"
      }
    ]
  },
  {
    id: 2,
    body: "Our selection for you *{{product}}* for just *{{price}}* 🤩 \n Shop Now and do not miss the opportunity😎.",
    bodyParameters: [{ key: "product" }, { key: "price" }],
    buttons: [
      {
        id: 1,
        type: "url"
      },
      {
        id: 2,
        type: "url"
      }
    ]
  },
  {
    id: 3,
    body: "Our selection for you *{{product}}* for just *{{price}}* 🤩 \n Shop Now and do not miss the opportunity😎.",
    bodyParameters: [{ key: "product" }, { key: "price" }],
    buttons: [
      {
        id: 1,
        type: "url"
      },
      {
        id: 2,
        type: "url"
      }
    ]
  }
];
type Card = {
  id: number;
  product: string | null;
  price: string | null;
  media: string | null;
};
type Props = {
  name?: string | null;
  cards?: Card[];
};

export const CampaignPreview = ({ name, cards }: Props) => {
  useEffect(() => {
    const carousel = document.querySelector(".carousel") as HTMLElement | null;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    if (!carousel) return; // Type guard to ensure carousel exists

    // Mouse Down
    const handleMouseDown = (e: MouseEvent) => {
      isDown = true;
      carousel.classList.add("active");
      startX = e.pageX - carousel.offsetLeft;
      scrollLeft = carousel.scrollLeft;
    };

    // Mouse Leave
    const handleMouseLeave = () => {
      isDown = false;
      carousel.classList.remove("active");
    };

    // Mouse Up
    const handleMouseUp = () => {
      isDown = false;
      carousel.classList.remove("active");
    };

    // Mouse Move
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - carousel.offsetLeft;
      const walk = (x - startX) * 2; // Faster scrolling
      carousel.scrollLeft = scrollLeft - walk;
    };

    // Add Event Listeners
    carousel.addEventListener("mousedown", handleMouseDown);
    carousel.addEventListener("mouseleave", handleMouseLeave);
    carousel.addEventListener("mouseup", handleMouseUp);
    carousel.addEventListener("mousemove", handleMouseMove);

    // Clean up event listeners on component unmount
    // eslint-disable-next-line consistent-return
    return () => {
      carousel.removeEventListener("mousedown", handleMouseDown);
      carousel.removeEventListener("mouseleave", handleMouseLeave);
      carousel.removeEventListener("mouseup", handleMouseUp);
      carousel.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  const formatTimestamp = (timestamp: Date) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };
  const parseText = (text: string) => {
    const parts = text.split(/(\*.*?\*)/g); // Split text by asterisk-enclosed parts
    return parts.map((part) => {
      if (part.startsWith("*") && part.endsWith("*")) {
        // Remove the asterisks and return a bold element
        return <strong key={part}>{part.slice(1, -1)}</strong>;
      }
      return part; // Return normal text
    });
  };
  const putParams = (text: string, id: number) =>
    text
      .replace("{{product}}", cards?.find((card) => card.id === id)?.product ?? "{{product}}")
      .replace("{{price}}", cards?.find((card) => card.id === id)?.price ?? "{{price}}");

  return (
    <div>
      <IPhonePreview>
        <div
          className="flex flex-col h-full bg-gray-200"
          style={{
            background: "url('/img/wallpaper.jpg') no-repeat center center/cover"
          }}
        >
          <div>
            <div className="bg-green-700 p-4 text-start font-bold flex flex-row items-center gap-2 ">
              <div className="rounded-full w-10 h-10 bg-white p-2">
                <img src="/img/icons/user.svg" alt="user icon" />{" "}
              </div>
              <div className="flex flex-col">
                <span className="text-white">My Customer</span>
                <span className="text-xs text-gray-300">Online</span>
              </div>
            </div>
          </div>

          <div className="flex-1 p-4 overflow-y-auto space-y-1">
            <div className="bg-white max-w-[80%] rounded-lg self-end shadow-md break-words ">
              <div className="p-3 flex flex-col">
                <div className="whitespace-pre-wrap text-[14px]">
                  {parseText(
                    `Hello ${
                      name ?? "{{Name}}"
                    }! 👋 \n We are excited to introduce new products we think you’ll love! 🌟 Discover the latest additions and treat yourself to something special. ✨ \n Do not miss out—shop now and let us know what you think! 😉`
                  )}
                </div>
                <div className="text-xs text-gray-500 mt-1 text-right">
                  {formatTimestamp(new Date())}
                </div>
              </div>
            </div>
            {/* Carousel Section */}
            <div>
              {/* Carousel container */}
              <div className="relative  w-full overflow-hidden cursor-move">
                <div
                  className="carousel flex space-x-1 overflow-x-auto scrollbar-hide"
                  style={{ scrollBehavior: "smooth" }}
                >
                  {carouselCards.map((carousel) => (
                    <div
                      key={carousel.id}
                      className="w-[80%] p-2 bg-white rounded-lg shadow-md flex-shrink-0"
                    >
                      <div className=" max-h-[180px] rounded-md bg-cover overflow-hidden">
                        <img
                          className=""
                          src={
                            cards?.find((card) => card.id === carousel.id)?.media ??
                            "/img/no-img.png"
                          }
                          alt="Header Preview"
                          // style={{ maxWidth: "100%", height: "200px" }}
                        />
                      </div>

                      {/* Text content */}
                      <div className="whitespace-pre-wrap text-sm py-2">
                        {parseText(putParams(carousel.body, carousel.id))}
                      </div>
                      {carousel.buttons.length > 0 && (
                        <div className=" border-t border-gray-300 flex flex-col w-full text-center px-1">
                          <div>
                            <div className=" w-full border-t border-t-gray-300" />
                            <div className="flex flex-row text-center self-center justify-center items-center gap-1  text-blue-400 p-2 ">
                              <img
                                className=" w-5 text-blue-400"
                                src="/img/icons/arrow-top-right-on-square.svg"
                                alt=""
                              />

                              <p className="text-blue-400 text-[13px]">Visit Our Website</p>
                            </div>
                            <div>
                              <div className=" w-full border-t border-t-gray-300" />
                              <div className="flex flex-row text-center self-center justify-center items-center gap-1  text-blue-400 p-2 ">
                                <img
                                  className=" w-5 text-blue-400"
                                  src="/img/icons/arrow-top-right-on-square.svg"
                                  alt=""
                                />

                                <p className="text-blue-400 text-[13px]">Download Our Brochure</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="flex items-center p-4 bg-white border-t border-gray-300">
            <input
              type="text"
              placeholder="Type a message"
              className="w-full py-2 px-4 rounded-full bg-gray-100 border border-gray-300 outline-none"
            />
          </div>
        </div>
      </IPhonePreview>
    </div>
  );
};
