import { IPhonePreview } from "./IphonePreview";
import { useCreateCarousel } from "./provider/CreateCarouselContext";
import { CarouselSwiper } from "./CarouselCardsSwiper";

export const CarouselPreview = () => {
  const { text, carouselCards } = useCreateCarousel();
  const formatTimestamp = (timestamp: Date) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };
  const parseText = (text: string) => {
    const parts = text.split(/(\*.*?\*)/g); // Split text by asterisk-enclosed parts
    return parts.map((part) => {
      if (part.startsWith("*") && part.endsWith("*")) {
        // Remove the asterisks and return a bold element
        return <strong key={part}>{part.slice(1, -1)}</strong>;
      }
      return part; // Return normal text
    });
  };
  return (
    <div>
      <IPhonePreview>
        <div
          className="flex flex-col h-full bg-gray-200"
          style={{
            background: "url('/img/wallpaper.jpg') no-repeat center center/cover"
          }}
        >
          <div>
            <div className="bg-green-700 p-4 text-start font-bold flex flex-row items-center gap-2 ">
              <div className="rounded-full w-10 h-10 bg-white p-2">
                <img src="/img/icons/user.svg" alt="user icon" />{" "}
              </div>
              <div className="flex flex-col">
                <span className="text-white">My Customer</span>
                <span className="text-xs text-gray-300">Online</span>
              </div>
            </div>
          </div>

          <div className="flex-1 p-4 overflow-y-auto space-y-1">
            <div className="bg-white max-w-[80%] rounded-lg self-end shadow-md break-words ">
              <div className="p-3 flex flex-col">
                <div className="whitespace-pre-wrap text-[14px]">{parseText(text)}</div>
                <div className="text-xs text-gray-500 mt-1 text-right">
                  {formatTimestamp(new Date())}
                </div>
              </div>
            </div>
            {/* Carousel Section */}
            <CarouselSwiper carouselCards={carouselCards} />
          </div>

          <div className="flex items-center p-4 bg-white border-t border-gray-300">
            <input
              type="text"
              placeholder="Type a message"
              className="w-full py-2 px-4 rounded-full bg-gray-100 border border-gray-300 outline-none"
            />
          </div>
        </div>
      </IPhonePreview>
    </div>
  );
};
