import { Button } from "@/components/Buttons";
import { FileUploader, Select, SelectField, TextArea, TextField } from "@/components/Forms";
import React from "react";
import { useForm } from "react-hook-form";
import { CarouselPreview } from "./CarouselPreview";
import { useCreateCarousel } from "./provider/CreateCarouselContext";

export const CreateCarousel = () => {
  const {
    carouselCards,
    setText,
    handleAddCarousel,
    handleRemoveCarousel,
    handleAddCarouselButton,
    handleRemoveCarouselButton,
    handleUpdateCarousel,
    handleUpdateCarouselButton
  } = useCreateCarousel();
  const { register } = useForm();
  return (
    <div className="flex flex-row relative">
      <div className="z-10 ">
        <CarouselPreview />
      </div>
      <div className=" w-full flex flex-col gap-3 p-10">
        <div className=" flex flex-col gap-3 ">
          <div className=" flex flex-col gap-2 bg-white shadow-md rounded-md p-4">
            <h1 className=" font-bold">Template name and language </h1>
            <div className=" flex flex-row gap-2">
              <div className=" w-[800px]">
                <TextField
                  name="templateName"
                  placeholder="Name of The Template"
                  register={register}
                />
              </div>
              <div className="">
                <Select
                  name="langugae"
                  placeholder="Select Language"
                  register={register}
                  options={["Arabic", "English"]}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2 bg-white shadow-md rounded-md p-4 ">
            <h1 className=" font-bold">Body</h1>
            <div className=" w-[800px]">
              <TextArea
                onChange={(event) => setText(event.target.value)}
                name="body"
                placeholder="Body"
                rows={4}
                register={register}
              />
            </div>
          </div>
          <div className=" flex flex-col gap-3 bg-white shadow-md rounded-md p-4  ">
            <div className=" flex flex-col gap-2">
              <h1 className=" font-bold">
                Carousel.<span className=" text-sm font-normal">Optional</span>
              </h1>
              <p className=" text-xs">
                Create carousels that let customers view your best options and take action to it.
              </p>
              <div className=" w-48">
                <Button variant="btn-primary" onClick={() => handleAddCarousel()}>
                  <div className=" flex flex-row gap-2 items-center justify-center">
                    <span>Add Carousel</span>
                    <img className=" w-5 h-5" src="/img/icons/circle-plus-icon.svg" alt="" />
                  </div>
                </Button>
              </div>
            </div>
            {carouselCards?.map((carousel) => (
              <div
                key={carousel.order}
                className="flex flex-col gap-3 border-gray-300 border-4 p-3 w-[900px] relative"
              >
                <div className=" absolute right-2 top-2">
                  <Button variant="btn-ghost" onClick={() => handleRemoveCarousel(carousel.order)}>
                    <div className=" rounded-full w-5 h-5">
                      <img src="/img/icons/circle-minus-solid.svg" alt="" />
                    </div>
                  </Button>
                </div>

                <div>
                  <p>Media</p>
                  <div>
                    <FileUploader
                      name="carouselMedia"
                      accept="image/*"
                      onChange={(event: any) => {
                        const file = event.target.files[0];
                        if (file) {
                          const reader = new FileReader();
                          reader.onload = (e: any) => {
                            handleUpdateCarousel({
                              order: carousel.order,
                              key: "media",
                              value: e.target.result
                            });
                          };
                          reader.readAsDataURL(file); // Read the file as a Data URL
                        }
                      }}
                    />
                  </div>
                </div>
                <div className="w-[800px] flex flex-col gap-2 ">
                  <h1>Body</h1>
                  <div className=" w-[800px]">
                    <TextArea
                      onChange={(event) =>
                        handleUpdateCarousel({
                          order: carousel.order,
                          key: "body",
                          value: event.target.value
                        })
                      }
                      value={carousel.body}
                      name="carouselBody"
                      placeholder="Body"
                      rows={4}
                      register={register}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className=" w-96">
                    <Button
                      variant="btn-accent"
                      onClick={() => handleAddCarouselButton(carousel.order)}
                    >
                      <span>Add Carousel Button</span>
                      <img className=" w-5 h-5" src="/img/icons/circle-plus-icon.svg" alt="" />
                    </Button>
                  </div>
                  {carousel.buttons.map((button) => (
                    <div key={button.order} className=" w-[800px] border-gray-200 border-4 p-2">
                      <div className=" flex flex-row justify-between items-center">
                        <SelectField
                          options={[
                            { label: "Visit website", value: "Visit website" },
                            { label: "Call Phone Number", value: "Call Phone Number" },
                            { label: "Copy offer code", value: "Copy offer code" }
                          ]}
                          name="buttonType"
                          register={register}
                          placeholder="Select Button Type"
                          value={button.buttonType}
                          onChange={(event) =>
                            handleUpdateCarouselButton(carousel.order, {
                              order: button.order,
                              key: "buttonType",
                              value: event.target.value
                            })
                          }
                        />
                        <div className=" w-[250px]">
                          <TextField
                            name="buttonText"
                            placeholder="Button Text"
                            register={register}
                            value={button.buttonText}
                            onChange={(event) =>
                              handleUpdateCarouselButton(carousel.order, {
                                order: button.order,
                                key: "buttonText",
                                value: event.target.value
                              })
                            }
                          />
                        </div>
                        <div className=" w-[250px]">
                          <TextField
                            name="url"
                            placeholder="Website URL"
                            register={register}
                            value={button.buttonUrl}
                            onChange={(event) =>
                              handleUpdateCarouselButton(carousel.order, {
                                order: button.order,
                                key: "buttonUrl",
                                value: event.target.value
                              })
                            }
                          />
                        </div>
                        <Button
                          variant="btn-ghost"
                          onClick={() => handleRemoveCarouselButton(carousel.order, button.order)}
                        >
                          <div className=" rounded-full w-5 h-5">
                            <img src="/img/icons/circle-minus-solid.svg" alt="" />
                          </div>
                        </Button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
          <Button variant="btn-active">Submit for Review</Button>
        </div>
      </div>
    </div>
  );
};
