import { Button } from "@/components/Buttons";
import { FileUploader, Select, SelectField, TextArea, TextField } from "@/components/Forms";
import { useForm } from "react-hook-form";
import { Preview } from "./Preview";
import { useCreateTemplate } from "./provider/CreateTemplateContext";

export const CreateMarketingTemplate = () => {
  const { register } = useForm();
  const {
    setText,
    setHeader,
    setFooter,
    buttons,
    handleAddButton,
    handleRemoveButton,
    handleUpdateButton,
    setHeaderType,
    setHeaderImage,
    headerType
  } = useCreateTemplate();
  return (
    <div className="flex flex-row">
      <div className=" order-last">
        <Preview />
      </div>
      <div className=" w-full flex flex-col gap-3  p-4">
        <div className=" flex flex-col gap-3 ">
          <div className=" flex flex-col gap-2 bg-white shadow-md rounded-md p-4">
            <h1 className=" font-bold">Template name and language </h1>
            <div className=" flex flex-row gap-2">
              <div className=" w-[800px]">
                <TextField
                  name="templateName"
                  placeholder="Name of The Template"
                  register={register}
                />
              </div>
              <div className="">
                <Select
                  name="langugae"
                  placeholder="Select Language"
                  register={register}
                  options={["Arabic", "English"]}
                />
              </div>
            </div>
          </div>

          <div className="bg-white shadow-md rounded-md p-4 flex flex-col gap-2 ">
            <h1 className=" font-bold">Content</h1>
            <div className="w-[800px] flex flex-col gap-2 ">
              <h1>
                Header.<span className=" text-sm">Optional</span>
              </h1>
              <div className=" w-[800px]">
                <SelectField
                  name="headerType"
                  placeholder="type"
                  options={[
                    { label: "None", value: "None" },
                    { label: "Text", value: "Text" },
                    { label: "Image", value: "Image" },
                    { label: "Video", value: "Video" },
                    { label: "Document", value: "Document" }
                  ]}
                  onChange={(event) => setHeaderType(event.target.value)}
                  register={register}
                />
              </div>
              <div className=" w-[800px]">
                {headerType === "Text" && (
                  <TextField
                    name="header"
                    placeholder="Header"
                    register={register}
                    onChange={(event) => setHeader(event.target.value)}
                  />
                )}
                {headerType === "Video" && (
                  <FileUploader
                    name="headerDocument"
                    placeholder="Header"
                    accept="video/*"
                    onChange={() => {}}
                  />
                )}
                {headerType === "Document" && (
                  <FileUploader
                    name="headerDocument"
                    placeholder="Header"
                    accept="document/*"
                    onChange={() => {}}
                  />
                )}
                {headerType === "Image" && (
                  <FileUploader
                    name="headerImage"
                    placeholder="Header"
                    accept="image/*"
                    onChange={(event: any) => {
                      const file = event.target.files[0];
                      if (file) {
                        const reader = new FileReader();
                        reader.onload = (e: any) => {
                          setHeaderImage(e.target.result);
                        };
                        reader.readAsDataURL(file); // Read the file as a Data URL
                      }
                    }}
                  />
                )}
              </div>
            </div>
            <div className="w-[800px] flex flex-col gap-2 ">
              <h1>Body</h1>
              <div className=" w-[800px]">
                <TextArea
                  onChange={(event) => setText(event.target.value)}
                  name="body"
                  placeholder="Body"
                  rows={4}
                  register={register}
                />
              </div>
            </div>
            <div className="w-[800px] flex flex-col gap-2 ">
              <h1>
                Footer.<span className=" text-sm">Optional</span>
              </h1>
              <div className=" w-[800px]">
                <TextField
                  name="footer"
                  placeholder="Footer"
                  register={register}
                  onChange={(event) => setFooter(event.target.value)}
                />
              </div>
            </div>
          </div>

          <div className=" flex flex-col gap-3 bg-white shadow-md rounded-md p-4 ">
            <div className=" flex flex-col gap-2">
              <h1 className=" font-bold">
                Buttons.<span className=" text-sm font-normal">Optional</span>
              </h1>
              <p className=" text-xs">
                Create buttons that let customers respond to your message or take action. You can
                add up to ten buttons. If you add more than three buttons, they will appear in a
                list.
              </p>
              <div className=" w-48">
                <Button variant="btn-primary" onClick={() => handleAddButton()}>
                  <div className=" flex flex-row gap-2 items-center justify-center">
                    <span>Add button</span>{" "}
                    <img className=" w-5 h-5" src="/img/icons/circle-plus-icon.svg" alt="" />
                  </div>
                </Button>
              </div>
            </div>
            {buttons?.map((button) => (
              <div key={button.order} className=" w-[800px] border-gray-200 border-4 p-2">
                <div className=" flex flex-row justify-between items-center">
                  <SelectField
                    options={[
                      { label: "Visit website", value: "Visit website" },
                      { label: "Call Phone Number", value: "Call Phone Number" },
                      { label: "Copy offer code", value: "Copy offer code" }
                    ]}
                    name="buttonType"
                    register={register}
                    placeholder="Select Button Type"
                    value={button.buttonType}
                    onChange={(event) =>
                      handleUpdateButton({
                        order: button.order,
                        key: "buttonType",
                        value: event.target.value
                      })
                    }
                  />
                  <div className=" w-[250px]">
                    <TextField
                      name="buttonText"
                      placeholder="Button Text"
                      register={register}
                      value={button.buttonText}
                      onChange={(event) =>
                        handleUpdateButton({
                          order: button.order,
                          key: "buttonText",
                          value: event.target.value
                        })
                      }
                    />
                  </div>
                  <div className=" w-[250px]">
                    <TextField
                      name="url"
                      placeholder="Website URL"
                      register={register}
                      value={button.buttonUrl}
                      onChange={(event) =>
                        handleUpdateButton({
                          order: button.order,
                          key: "buttonUrl",
                          value: event.target.value
                        })
                      }
                    />
                  </div>
                  <Button variant="btn-ghost" onClick={() => handleRemoveButton(button.order)}>
                    <div className=" rounded-full w-5 h-5">
                      <img src="/img/icons/circle-minus-solid.svg" alt="" />
                    </div>
                  </Button>
                </div>
              </div>
            ))}
          </div>
          <Button variant="btn-active">Submit for Review</Button>
        </div>
      </div>
    </div>
  );
};
