import { Button } from "@/components/Buttons";
import { Checkbox, FileUploader, SelectField, TextField } from "@/components/Forms";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useCreateCampaign } from "../../Context/CreateCampaignContext";
import { CampaignPreview } from "./CreatePreview";

const template = [
  {
    id: 1,
    Name: "Products Carousel",
    type: "Carousel",
    text: "Hello {{1}}! 👋 \n We are excited to introduce new products we think you’ll love! 🌟 Discover the latest additions and treat yourself to something special. ✨ \n Do not miss out—shop now and let us know what you think! 😉",
    parameters: [
      {
        key: "Name"
      }
    ],
    carouselCards: [
      {
        id: 1,
        body: "Our selection for you *{{1}}* for just *{{2}}* 🤩 \n Shop Now and do not miss the opportunity😎.",
        bodyParameters: [{ key: "product" }, { key: "price" }],
        buttons: [
          {
            id: 1,
            type: "url"
          },
          {
            id: 2,
            type: "url"
          }
        ]
      },
      {
        id: 2,
        body: "Our selection for you *{{1}}* for just *{{2}}* 🤩 \n Shop Now and do not miss the opportunity😎.",
        bodyParameters: [{ key: "product" }, { key: "price" }],
        buttons: [
          {
            id: 1,
            type: "url"
          },
          {
            id: 2,
            type: "url"
          }
        ]
      },
      {
        id: 3,
        body: "Our selection for you *{{1}}* for just *{{2}}* 🤩 \n Shop Now and do not miss the opportunity😎.",
        bodyParameters: [{ key: "product" }, { key: "price" }],
        buttons: [
          {
            id: 1,
            type: "url"
          },
          {
            id: 2,
            type: "url"
          }
        ]
      }
    ]
  }
];

export const SelectTemplate = () => {
  const { setStep } = useCreateCampaign();
  const [selected, setSelected] = useState<boolean>(false);
  const [name, setName] = useState<string | null>(null);
  const [checked, setChecked] = useState<boolean>(false);
  const [cards, setCards] = useState<
    { id: number; product: string | null; price: string | null; media: string | null }[]
  >([
    {
      id: 1,
      product: null,
      price: null,
      media: null
    },
    {
      id: 2,
      product: null,
      price: null,
      media: null
    },
    {
      id: 3,
      product: null,
      price: null,
      media: null
    }
  ]);
  const { register } = useForm();
  return (
    <div className="flex flex-col w-full p-10 items-center justify-center">
      <div className="flex flex-row gap-2 p-3">
        <div>
          <SelectField
            name="templateName"
            options={[{ label: "Product Carousel", value: "1" }]}
            register={register}
            onChange={() => setSelected(true)}
          />
        </div>
        <div>
          <Button variant="btn-accent">Create New</Button>
        </div>
      </div>
      {selected && (
        <div className="flex flex-row w-full items-center justify-center ">
          <div className="flex flex-col w-[50%] gap-3">
            <div className="flex flex-col bg-white rounded-md shadow-md  p-3 gap-2">
              <h1 className=" font-semibold">Body parameters</h1>
              <div className="flex flex-col">
                {template[0].parameters.map((param) => (
                  <div key={param.key} className="flex flex-row gap-4 justify-center items-center">
                    <p>{param.key}:</p>
                    <div>
                      <TextField
                        disabled={checked}
                        register={register}
                        name={param.key}
                        value={name ?? ""}
                        onChange={(event) => setName(event.target.value)}
                      />
                    </div>
                    <div className="flex flex-row gap-0">
                      <Checkbox
                        name="customerName"
                        register={register}
                        placeholder="Use Customer Name"
                        onChange={(event) => {
                          setChecked(event.target.checked);
                          setName("My Customer");
                        }}
                      />
                      <p>Use Customer Name</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col bg-white rounded-md shadow-md  p-3 gap-2">
              <h1 className=" font-semibold">Body parameters</h1>
              <div className="flex flex-col gap-2">
                {template[0].carouselCards.map((carousel) => (
                  <div key={carousel.id} className="collapse bg-base-200 rounded-sm">
                    <input type="checkbox" />
                    <div className="collapse-title text-xl font-medium">
                      <div className="flex flex-row">
                        <p> Card {carousel.id}</p>
                      </div>
                    </div>
                    <div className="collapse-content">
                      <div className="flex flex-col gap-2 ">
                        <div className="flex flex-row gap-2 items-center">
                          <p className=" w-32">Media:</p>
                          <FileUploader
                            name="media"
                            accept="image/*"
                            onChange={(event: any) => {
                              const file = event.target.files[0];
                              if (file) {
                                const reader = new FileReader();
                                reader.onload = (e: any) => {
                                  setCards(
                                    cards.map((card) => {
                                      if (card.id === carousel.id) {
                                        return { ...card, media: e.target.result };
                                      }
                                      return card;
                                    })
                                  );
                                };
                                reader.readAsDataURL(file); // Read the file as a Data URL
                              }
                            }}
                          />
                        </div>
                        <div className="flex flex-col gap-2">
                          <div className="flex flex-row gap-2 w-full items-center">
                            <div className="flex flex-col w-32">
                              <p>Product:</p>
                            </div>
                            <div className="flex flex-col w-[70%]">
                              <TextField
                                register={register}
                                name={`product-${carousel.id}`}
                                value={cards.find((card) => card.id === carousel.id)?.product ?? ""}
                                onChange={(event) =>
                                  setCards(
                                    cards.map((card) => {
                                      if (card.id === carousel.id) {
                                        return { ...card, product: event?.target?.value };
                                      }
                                      return card;
                                    })
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="flex flex-row gap-2 w-full items-center">
                            <div className="flex flex-col w-32">
                              <p>Price:</p>
                            </div>
                            <div className="flex flex-col w-[70%]">
                              <TextField
                                register={register}
                                name={`price-${carousel.id}`}
                                value={cards.find((card) => card.id === carousel.id)?.price ?? ""}
                                onChange={(event) =>
                                  setCards(
                                    cards.map((card) => {
                                      if (card.id === carousel.id) {
                                        return { ...card, price: event?.target?.value };
                                      }
                                      return card;
                                    })
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="flex flex-col gap-2">
                          {carousel.buttons.map((button) => (
                            <div
                              key={button.id}
                              className="flex flex-row gap-2 w-full items-center"
                            >
                              <div className="flex flex-col w-32">
                                <p>{`Button ${button.id} URL`}</p>
                              </div>
                              <div className="flex flex-col w-[70%]">
                                <TextField register={register} name="URL" />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" w-full flex flex-row justify-center">
              <Button onClick={() => setStep(2)} variant="btn-active">
                Next
              </Button>
            </div>
          </div>

          <div>
            <CampaignPreview name={name} cards={cards} />
          </div>
        </div>
      )}
    </div>
  );
};
