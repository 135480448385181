/* eslint-disable no-nested-ternary */
const campaigns = [
  {
    id: 1,
    name: "Winter Sale",
    templateCategory: "Products Carousel",
    language: "English",
    cost: 5,
    messagesDelivered: 500,
    readRate: "75.50%"
  },
  {
    id: 2,
    name: "Spring Launch",
    templateCategory: "Products Carousel",
    language: "English",
    cost: 5,
    messagesDelivered: 300,
    readRate: "65.20%"
  },
  {
    id: 3,
    name: "Summer Fiesta",
    templateCategory: "Products Carousel",
    language: "English",
    cost: 5,
    messagesDelivered: 250,
    readRate: "45.30%"
  },
  {
    id: 4,
    name: "Fall Festival",
    templateCategory: "Products Carousel",
    language: "Arabic",
    cost: 5,
    messagesDelivered: 800,
    readRate: "82.40%"
  },
  {
    id: 5,
    name: "Black Friday Blitz",
    templateCategory: "Products Carousel",
    language: "Arabic",
    cost: 5,
    messagesDelivered: 1000,
    readRate: "90.10%"
  },
  {
    id: 6,
    name: "Cyber Monday Deals",
    templateCategory: "Products Carousel",
    language: "Arabic",
    cost: 5,
    messagesDelivered: 600,
    readRate: "60.00%"
  },
  {
    id: 7,
    name: "New Year Celebration",
    templateCategory: "Products Carousel",
    language: "Arabic",
    cost: 5,
    messagesDelivered: 200,
    readRate: "50.25%"
  },
  {
    id: 8,
    name: "Valentine's Day Special",
    templateCategory: "Products Carousel",
    language: "Arabic",
    cost: 5,
    messagesDelivered: 400,
    readRate: "70.90%"
  },
  {
    id: 9,
    name: "Easter Extravaganza",
    templateCategory: "Products Carousel",
    language: "Arabic",
    cost: 5,
    messagesDelivered: 700,
    readRate: "85.75%"
  },
  {
    id: 10,
    name: "Halloween Spooktacular",
    templateCategory: "Products Carousel",
    language: "Arabic",
    cost: 5,
    messagesDelivered: 350,
    readRate: "68.40%"
  }
];

export const CampaignsList = () => (
  <div className="flex justify-center w-full">
    <div className="overflow-x-auto w-full">
      <table className="table w-full">
        {/* Head */}
        <thead>
          <tr>
            <th>
              <div>
                <input type="checkbox" className="checkbox" />
              </div>
            </th>
            <th>Campaign Name</th>
            <th>Template</th>
            <th>Language</th>
            <th>cost</th>
            <th>Messages Delivered</th>
            <th>Read Rate</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {campaigns.map((campaign) => (
            <tr key={campaign.id}>
              <th>
                <div>
                  <input type="checkbox" className="checkbox" />
                </div>
              </th>
              <td>
                <div className="flex items-center gap-3">
                  <div>
                    <div className="font-bold">{campaign.name}</div>
                  </div>
                </div>
              </td>
              <td>{campaign.templateCategory}</td>
              <td>{campaign.language}</td>
              <td>
                <span>{`${campaign.cost} SAR`}</span>
              </td>
              <td>{campaign.messagesDelivered}</td>
              <td>{campaign.readRate}</td>
              <th>
                <button type="button" className="btn btn-ghost btn-xs">
                  <button type="button">Use Again</button>
                </button>
              </th>
            </tr>
          ))}
        </tbody>
        {/* Foot */}
      </table>
    </div>
  </div>
);
