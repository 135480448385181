import { Button } from "@/components/Buttons";
import React from "react";
import { Group } from "./components/groups/Group";

const mockGroups = [
  { id: 1, name: "All Customers", customerCount: 120 },
  { id: 2, name: "Jeddah Branch", customerCount: 95 },
  { id: 3, name: "High Buyers", customerCount: 45 },
  { id: 4, name: "All Arab National", customerCount: 80 },
  { id: 5, name: "Indian National", customerCount: 65 },
  { id: 6, name: "Saudi National", customerCount: 30 }
];

export const Groups = () => (
  <div className="border-gray-200 border-r p-4">
    <h2 className="text-lg font-bold mb-4">Groups</h2>
    <Button variant="btn-active">
      <div className=" w-full">Create New</div>
    </Button>
    {mockGroups.map((group) => (
      <Group key={group.id} groupName={group.name} customerCount={group.customerCount} />
    ))}
  </div>
);
