import React, { useMemo, useState } from "react";

interface CreateCampaignType {
  step: number;
  setStep: (step: number) => void;
}
const defaultCampaign: CreateCampaignType = {
  step: 1,
  setStep: () => {}
};
const CreateCampaignContext = React.createContext<CreateCampaignType>(defaultCampaign);

export const CreateCampaignProvider = ({ children }: { children: React.ReactNode }) => {
  const [step, setStep] = useState<number>(1);

  const value = useMemo(() => ({ step, setStep }), [step, setStep]);
  return <CreateCampaignContext.Provider value={value}>{children}</CreateCampaignContext.Provider>;
};

export const useCreateCampaign = () => {
  const context = React.useContext(CreateCampaignContext);
  if (!context) {
    throw new Error("useCreateCampaign must be used within a CreateCampaignProvider");
  }
  return context;
};
