import React from "react";
import { useCreateCampaign } from "../../Context/CreateCampaignContext";

export const Steps = () => {
  const { step } = useCreateCampaign();

  return (
    <ul className="steps p-3 rounded-md border border-gray-200 bg-white shadow-md w-[50%]">
      <li className={`step ${step >= 1 && "step-primary"}`}>Template</li>
      <li className={`step ${step >= 2 && "step-primary"}`}>Audience</li>
      <li className={`step ${step === 3 && "step-primary"}`}>Schedule</li>
    </ul>
  );
};
