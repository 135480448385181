import "reflect-metadata";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
import { QueryClientProvider, QueryClient } from "react-query";
import { container, Injector, SERVICES_TYPES } from "@/ioc";
import { AppStore } from "@/store/root";
import App from "./App";

import "./index.css";
import { CreateTemplateProvider } from "./pages/Marketing/provider/CreateTemplateContext";
import { CreateCarouselProvider } from "./pages/Marketing/provider/CreateCarouselContext";
import { CreateCampaignProvider } from "./pages/Campaign/Context/CreateCampaignContext";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const store = container.get<AppStore>(SERVICES_TYPES.STORE);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 30000,
      refetchOnWindowFocus: false
    }
  }
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Injector container={container}>
        <Provider store={store}>
          <BrowserRouter basename="/">
            <CreateTemplateProvider>
              <CreateCarouselProvider>
                <CreateCampaignProvider>
                  <App />
                </CreateCampaignProvider>
              </CreateCarouselProvider>
            </CreateTemplateProvider>
          </BrowserRouter>
        </Provider>
      </Injector>
    </QueryClientProvider>
  </React.StrictMode>
);
