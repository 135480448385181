import { Button } from "@/components/Buttons";
import { CustomersList } from "@/pages/Customers/CustomersList";
import React from "react";
import { useCreateCampaign } from "../../Context/CreateCampaignContext";
import { CustomersGroups } from "./CustomersGroups";

export const SelectAudience = () => {
  const { setStep } = useCreateCampaign();
  return (
    <div className="flex w-full items-center flex-col bg-white p-10">
      <CustomersGroups />
      <CustomersList />
      <div className=" w-full flex flex-row justify-between">
        <Button onClick={() => setStep(1)} variant="btn-active">
          Previous
        </Button>
        <Button onClick={() => setStep(3)} variant="btn-active">
          Next
        </Button>
      </div>
    </div>
  );
};
