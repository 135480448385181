import React from "react";
import { CampaignsList } from "./components/CampaignsList";
import { Header } from "./components/header";

export const Campaign = () => (
  <div className="flex items-center justify-center">
    <div className="flex flex-col gap-2 p-5  w-[90%] ">
      <Header />
      <CampaignsList />
    </div>
  </div>
);
