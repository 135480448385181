import { Button } from "@/components/Buttons";
import { useState } from "react";

type Props = {
  onQChange: (filters: Record<string, string>) => void; // Updated to accept multiple filters
};

export const Filter = ({ onQChange }: Props) => {
  const [name, setName] = useState<string>("");
  const [nationality, setNationality] = useState<string>("");
  const [branch, setBranch] = useState<string>("");
  const [status, setStatus] = useState<string>("");
  const [paymentMethod, setPaymentMethod] = useState<string>("");
  const [joinDate] = useState<string>("");

  const handleFilterChange = () => {
    // Create an object with all filter values
    const filters = {
      name,
      nationality,
      branch,
      status,
      paymentMethod,
      joinDate
    };
    onQChange(filters); // Pass the filters object to the parent component
  };

  return (
    <div className="flex flex-row items-center flex-wrap  gap-3">
      {/* Name Filter */}
      <div className="relative mb-2">
        <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
            <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z" />
          </svg>
        </span>
        <input
          onChange={(e) => setName(e.target.value)}
          placeholder="Customer Name"
          className="h-full appearance-none rounded border block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
        />
      </div>

      {/* Nationality Filter */}
      <div className="relative mb-2">
        <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
            <path d="M12 2C8.14 2 5 5.14 5 9c0 3.88 7 11 7 11s7-7.12 7-11c0-3.86-3.14-7-7-7zm0 3a3 3 0 100 6 3 3 0 000-6z" />
          </svg>
          {/* <svg
            viewBox="0 0 24 24"
            className="h-full absolute inset-y-0 left-0 flex items-center pl-2"
          >
            <path className=" h-4" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
          </svg> */}
        </span>
        <input
          onChange={(e) => setNationality(e.target.value)}
          placeholder="Nationality"
          className="h-full appearance-none rounded border block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
        />
      </div>

      {/* Branch Filter */}
      <div className="relative mb-2">
        <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current stroke-none text-gray-500">
            <path d="M12 2C8.14 2 5 5.14 5 9c0 3.88 7 11 7 11s7-7.12 7-11c0-3.86-3.14-7-7-7zm0 3a3 3 0 100 6 3 3 0 000-6z" />
          </svg>
        </span>
        <input
          onChange={(e) => setBranch(e.target.value)}
          placeholder="Branch"
          className="h-full appearance-none rounded border block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
        />
      </div>

      {/* Status Filter */}
      <div className="relative mb-2">
        <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
            <path d="M3 3h18v2H3V3zm0 4h18v2H3V7zm0 4h18v2H3v-2zm0 4h18v2H3v-2zm0 4h18v2H3v-2z" />
          </svg>
        </span>
        <input
          onChange={(e) => setStatus(e.target.value)}
          placeholder="Status"
          className="h-full appearance-none rounded border block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
        />
      </div>

      {/* Payment Method Filter */}
      <div className="relative mb-2">
        <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
            <path d="M2 5h20v14H2V5zm2 0v14h16V5H4z" />
          </svg>
        </span>
        <input
          onChange={(e) => setPaymentMethod(e.target.value)}
          placeholder="Payment Method"
          className="h-full appearance-none rounded border block pl-8 pr-6 py-2 w-full bg-white text-sm placeholder-gray-400 text-gray-700 focus:bg-white focus:placeholder-gray-600 focus:text-gray-700 focus:outline-none"
        />
      </div>

      {/* Join Date Filter */}
      {/* <div className="relative mb-2">
        <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
          <svg viewBox="0 0 24 24" className="h-4 w-4 fill-current text-gray-500">
            <path d="M3 5h18v2H3V5zm0 4h18v2H3V9zm0 4h18v2H3v-2zm0 4h18v2H3v-2zm0 4h18v2H3v-2z" />
          </svg>
        </span>
        <input
          type="date" // Change input type to date
          onChange={(e) => setJoinDate(e.target.value)}
          className="h-full appearance-none rounded border block pl-8 pr-6 py-2 w-full bg-white text-sm text-gray-700 focus:bg-white focus:outline-none"
        />
      </div> */}

      <div className="items-center mx-3 my-3">
        <Button variant="btn-block" onClick={handleFilterChange}>
          Filter
        </Button>
      </div>
    </div>
  );
};
