import React from "react";
import { Header } from "./Header";
import { TemplatesList } from "./TemplatesList";

export const Templates = () => (
  <div className="flex items-center justify-center">
    <div className="flex flex-col gap-2 p-5  w-[90%] ">
      <Header />
      <TemplatesList />
    </div>
  </div>
);
