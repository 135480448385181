import { Button } from "@/components/Buttons";
import { Select, TextField } from "@/components/Forms";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

export const Header = () => {
  const { register } = useForm();
  const navigate = useNavigate();

  return (
    <div className=" flex flex-row justify-between bg-white shadow-sm py-4 px-2 gap-4">
      <div className=" w-52">
        <TextField name="search" register={register} placeholder="search" />
      </div>
      <div className=" w-52">
        <Select
          placeholder="Category"
          name="categry"
          register={register}
          options={["marketing", "utility"]}
        />
      </div>
      <div className=" w-52">
        <Select
          placeholder="Status"
          name="status"
          register={register}
          options={[
            "In review",
            "Rejected",
            "Active – Quality pending",
            "Active – High quality",
            "Active – Medium quality",
            "Active – Low quality",
            "Paused",
            "Disabled",
            "Appealed – In review"
          ]}
        />
      </div>
      <div className=" w-52">
        <Select
          placeholder="Langugae"
          name="language"
          register={register}
          options={["Arabic", "English", "Portuguese", "Spanish"]}
        />
      </div>
      <div className=" w-52">
        <Select
          placeholder="Date"
          name="date"
          register={register}
          options={["Last 7 days", "Last 30 days", "Last 60 days", "Last 90 days"]}
        />
      </div>
      <Button
        title="Create a Template"
        variant="btn-primary"
        onClick={() => navigate("/marketing/templates/create-template")}
      >
        Create a Template
      </Button>
    </div>
  );
};
