import React, { useEffect } from "react";
import { CarouselCard } from "@/models/templatees";

interface CarouselSwiperProps {
  carouselCards: CarouselCard[];
}

export const CarouselSwiper: React.FC<CarouselSwiperProps> = ({ carouselCards }) => {
  useEffect(() => {
    const carousel = document.querySelector(".carousel") as HTMLElement | null;
    let isDown = false;
    let startX: number;
    let scrollLeft: number;

    if (!carousel) return; // Type guard to ensure carousel exists

    // Mouse Down
    const handleMouseDown = (e: MouseEvent) => {
      isDown = true;
      carousel.classList.add("active");
      startX = e.pageX - carousel.offsetLeft;
      scrollLeft = carousel.scrollLeft;
    };

    // Mouse Leave
    const handleMouseLeave = () => {
      isDown = false;
      carousel.classList.remove("active");
    };

    // Mouse Up
    const handleMouseUp = () => {
      isDown = false;
      carousel.classList.remove("active");
    };

    // Mouse Move
    const handleMouseMove = (e: MouseEvent) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - carousel.offsetLeft;
      const walk = (x - startX) * 2; // Faster scrolling
      carousel.scrollLeft = scrollLeft - walk;
    };

    // Add Event Listeners
    carousel.addEventListener("mousedown", handleMouseDown);
    carousel.addEventListener("mouseleave", handleMouseLeave);
    carousel.addEventListener("mouseup", handleMouseUp);
    carousel.addEventListener("mousemove", handleMouseMove);

    // Clean up event listeners on component unmount
    // eslint-disable-next-line consistent-return
    return () => {
      carousel.removeEventListener("mousedown", handleMouseDown);
      carousel.removeEventListener("mouseleave", handleMouseLeave);
      carousel.removeEventListener("mouseup", handleMouseUp);
      carousel.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);
  const parseText = (text: string) => {
    const parts = text.split(/(\*.*?\*)/g); // Split text by asterisk-enclosed parts
    return parts.map((part) => {
      if (part.startsWith("*") && part.endsWith("*")) {
        // Remove the asterisks and return a bold element
        return <strong key={part}>{part.slice(1, -1)}</strong>;
      }
      return part; // Return normal text
    });
  };
  return (
    <div>
      {/* Carousel container */}
      <div className="relative  w-full overflow-hidden cursor-move">
        <div
          className="carousel flex space-x-1 overflow-x-auto scrollbar-hide"
          style={{ scrollBehavior: "smooth" }}
        >
          {carouselCards.map((carousel) => (
            <div
              key={carousel.order}
              className="w-[80%] p-2 bg-white rounded-lg shadow-md flex-shrink-0"
            >
              <div className=" max-h-[180px] rounded-md bg-cover overflow-hidden">
                <img
                  className=""
                  src={carousel.media ?? "/img/no-img.png"}
                  alt="Header Preview"
                  // style={{ maxWidth: "100%", height: "200px" }}
                />
              </div>

              {/* Text content */}
              <div className="whitespace-pre-wrap text-sm py-2">{parseText(carousel.body)}</div>
              {carousel.buttons.length > 0 && (
                <div className=" border-t border-gray-300 flex flex-col w-full text-center px-1">
                  {carousel.buttons.map((button) => (
                    <div key={button.order}>
                      <div className=" w-full border-t border-t-gray-300" />
                      <div
                        key={button.order}
                        className="flex flex-row text-center self-center justify-center items-center gap-1  text-blue-400 p-2 "
                      >
                        {button.buttonType === "Visit website" ? (
                          <img
                            className=" w-5 text-blue-400"
                            src="/img/icons/arrow-top-right-on-square.svg"
                            alt=""
                          />
                        ) : (
                          <img className=" w-5 text-blue-400" src="/img/icons/phone.svg" alt="" />
                        )}
                        <p className="text-blue-400 text-[13px]">{button.buttonText}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CarouselSwiper;
