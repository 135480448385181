/* eslint-disable no-nested-ternary */
import { BreadCrumb } from "@/components/BreadCrumb";
import { CustomersList } from "./CustomersList";
import { Groups } from "./Groups";

export const Customers = () => (
  <div className="">
    <BreadCrumb items={[{ text: "Customers", isActive: true }]} />
    {/* <Broadcasting /> */}
    <div className="flex flex-row">
      <div className="p-9">
        <Groups />
      </div>
      <CustomersList />
    </div>
  </div>
);
