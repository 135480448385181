import { IPhonePreview } from "./IphonePreview";
import { useCreateTemplate } from "./provider/CreateTemplateContext";

export const Preview = () => {
  const { text, header, footer, buttons, headerType, headerImage } = useCreateTemplate();

  const formatTimestamp = (timestamp: Date) => {
    const date = new Date(timestamp);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };
  const parseText = (text: string) => {
    const parts = text.split(/(\*.*?\*)/g); // Split text by asterisk-enclosed parts
    return parts.map((part) => {
      if (part.startsWith("*") && part.endsWith("*")) {
        // Remove the asterisks and return a bold element
        return <strong key={part}>{part.slice(1, -1)}</strong>;
      }
      return part; // Return normal text
    });
  };
  return (
    <div>
      <IPhonePreview>
        <div
          className="flex flex-col h-full bg-gray-200"
          style={{ background: "url('/img/wallpaper.jpg') no-repeat center center/cover" }}
        >
          <div>
            <div className="bg-green-700 p-4 text-start font-bold flex flex-row items-center gap-2 ">
              <div className=" rounded-full w-10 h-10 bg-white p-2">
                <img src="/img/icons/user.svg" alt="user icon" />{" "}
              </div>
              <div className="flex flex-col">
                <span className=" text-white">My Customer</span>
                <span className=" text-xs text-gray-300">Online</span>
              </div>
            </div>
          </div>

          <div className="flex-1 p-4 overflow-y-auto space-y-1  ">
            <div className="bg-white max-w-[80%] rounded-lg self-end shadow-md break-words ">
              <div className="p-3 flex flex-col">
                <div className=" py-1">
                  {header && headerType === "Text" && (
                    <div className="font-bold text-[13px]">{header}</div>
                  )}
                  {headerType === "Image" && headerImage && (
                    <img
                      src={headerImage}
                      alt="Header Preview"
                      // style={{ maxWidth: "100%", height: "200px" }}
                    />
                  )}
                  {headerType === "Document" && (
                    <img
                      src="/img/pdf.png"
                      alt="Header Preview"
                      // style={{ maxWidth: "100%", height: "200px" }}
                    />
                  )}
                  {headerType === "Video" && headerImage && (
                    <img
                      src="/img/video.png"
                      alt="Header Preview"
                      // style={{ maxWidth: "100%", height: "200px" }}
                    />
                  )}
                </div>

                <div className="whitespace-pre-wrap text-[14px]">{parseText(text)}</div>
                {/* Render the timestamp */}
                {footer && <div className=" text-gray-500 text-[13px]">{footer}</div>}
                <div className="text-xs text-gray-500 mt-1 text-right">
                  {formatTimestamp(new Date())}
                </div>
              </div>

              {buttons.length > 0 && (
                <div className=" border-t border-gray-300 flex flex-col w-full text-center px-1">
                  {buttons.map((button) => (
                    <div key={button.order}>
                      <div className=" w-full border-t border-t-gray-300" />
                      <div
                        key={button.order}
                        className="flex flex-row text-center self-center justify-center items-center gap-1  text-blue-400 p-2 "
                      >
                        {button.buttonType === "Visit website" ? (
                          <img
                            className=" w-5 text-blue-400"
                            src="/img/icons/arrow-top-right-on-square.svg"
                            alt=""
                          />
                        ) : (
                          <img className=" w-5 text-blue-400" src="/img/icons/phone.svg" alt="" />
                        )}
                        <p className="text-blue-400 text-[13px]">{button.buttonText}</p>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="flex items-center p-4 bg-white border-t border-gray-300">
            <input
              type="text"
              placeholder="Type a message"
              className="w-full py-2 px-4 rounded-full bg-gray-100 border border-gray-300 outline-none"
            />
          </div>
        </div>
      </IPhonePreview>
    </div>
  );
};
