/* eslint-disable @typescript-eslint/no-unused-vars */
import { CarouselCard, TemplateButton } from "@/models/templatees";
import React, { createContext, useState } from "react";

type typeCreateTemplateContextType = {
  name: string;
  category: string;
  language: string;
  header: string | null;
  headerType: string | null;
  headerParameters: string[] | null;
  headerImage: string | null;
  text: string;
  parameters: string[] | null;
  footer: string;
  buttons: TemplateButton[];
  setName: (prop: string) => void;
  setCategory: (prop: string) => void;
  setLanguage: (prop: string) => void;
  setHeader: (prop: string) => void;
  setHeaderType: (prop: string) => void;
  setHeaderParameters: (prop: string[]) => void;
  setHeaderImage: (prop: string) => void;
  setText: (prop: string) => void;
  setParameters: (prop: string[]) => void;
  setFooter: (prop: string) => void;
  handleAddButton: () => void;
  handleRemoveButton: (prop: number) => void;
  handleUpdateButton: (prop: { order: number; key: string; value: string }) => void;
};

const defaultTemplate: typeCreateTemplateContextType = {
  name: "",
  category: "",
  language: "",
  header: null,
  headerType: null,
  headerParameters: null,
  headerImage: null,
  text: "Hello...",
  parameters: null,
  footer: "",
  buttons: [],
  setName: () => {},
  setCategory: () => {},
  setLanguage: () => {},
  setHeader: () => {},
  setHeaderType: () => {},
  setHeaderParameters: () => {},
  setHeaderImage: () => {},
  setText: () => {},
  setParameters: () => {},
  setFooter: () => {},
  handleAddButton: () => {},
  handleRemoveButton: () => {},
  handleUpdateButton: () => {}
};

export const CreateTemplateContext = createContext<typeCreateTemplateContextType>(defaultTemplate);

export const CreateTemplateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [name, setName] = useState<string>("");
  const [category, setCategory] = useState<string>("");
  const [language, setLanguage] = useState<string>("");
  const [header, setHeader] = useState<string | null>(null);
  const [headerType, setHeaderType] = useState<string | null>(null);
  const [headerParameters, setHeaderParameters] = useState<string[] | null>(null);
  const [headerImage, setHeaderImage] = useState<string | null>(null);
  const [text, setText] = useState<string>("Hello...");
  const [parameters, setParameters] = useState<string[] | null>(null);
  const [footer, setFooter] = useState<string>("");
  const [buttons, setButtons] = useState<TemplateButton[]>([]);

  const handleAddButton = () => {
    // console.log(headerImage);
    const buttonOrder = buttons.length > 0 ? buttons[buttons.length - 1].order + 1 : 1;
    const button = {
      order: buttonOrder,
      buttonType: "Visit website",
      buttonText: "Visit Our website",
      buttonUrl: "",
      buttonPhoneNumber: ""
    };
    const newButtons = [...buttons, button];
    setButtons(newButtons);
  };

  const handleRemoveButton = (buttonOrder: number) => {
    const newButtons = buttons?.filter((button) => button.order !== buttonOrder);
    setButtons(newButtons);
  };

  const handleUpdateButton = (updates: { order: number; key: string; value: string }) => {
    // console.log(updates);
    const updatedButtons = buttons?.map((button) => {
      if (button.order === updates.order) {
        return {
          ...button,
          [updates.key]: updates.value
        };
      }
      return button; // Keep other buttons unchanged
    });
    setButtons(updatedButtons);
  };

  const value = React.useMemo(
    () => ({
      name,
      category,
      language,
      header,
      headerType,
      headerParameters,
      headerImage,
      text,
      parameters,
      footer,
      buttons,
      setName,
      setCategory,
      setLanguage,
      setHeader,
      setHeaderType,
      setHeaderImage,
      setHeaderParameters,
      setText,
      setParameters,
      setFooter,
      setButtons,
      handleAddButton,
      handleRemoveButton,
      handleUpdateButton
    }),
    [
      name,
      category,
      language,
      header,
      headerType,
      headerParameters,
      headerImage,
      text,
      parameters,
      footer,
      buttons,
      setName,
      setCategory,
      setLanguage,
      setHeader,
      setHeaderType,
      setHeaderParameters,
      setHeaderImage,
      setText,
      setParameters,
      setFooter,
      setButtons,
      handleAddButton,
      handleRemoveButton,
      handleUpdateButton
    ]
  );

  return <CreateTemplateContext.Provider value={value}>{children}</CreateTemplateContext.Provider>;
};

export const useCreateTemplate = () => {
  const context = React.useContext(CreateTemplateContext);
  if (!context) {
    throw new Error("useCreateTemplate must be used within a CreateTemplateProvider");
  }
  return context;
};
