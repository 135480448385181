/* eslint-disable @typescript-eslint/no-unused-vars */
import { TabController } from "@/components/Tab";
import { CreateCarousel } from "./CreateCarousel";
import { CreateMarketingTemplate } from "./CreateMarketingTemplate";
import { Preview } from "./Preview";

export const CreateTemplate = () => (
  <div>
    <section className="text-gray-700 body-font overflow-hidden relative">
      <div className="container p-5">
        <div className=" mx-auto flex flex-wrap">
          <TabController
            items={[
              {
                label: "Custome",
                order: 1,
                element: <CreateMarketingTemplate />
              },
              {
                label: "Carousel",
                order: 2,
                element: <CreateCarousel />
              }
            ]}
          />
        </div>
      </div>
    </section>
  </div>
);
